export const GRID_SETTINGS = {
  columns: "4fr 6fr",
  gap: "4rem",
  columnsSm: "1fr",
  collapse: true,
}

export const ALT_GRID_SETTINGS = {
  columns: "6fr 4fr",
  gap: "4rem",
  columnsSm: "1fr",
  collapse: true,
}

export const IMAGE_SETTINGS = {
  center: true,
  height: "min-content",
  padding: "4rem 2rem",
  borderRadius: "2rem",
}

export const HEADING_SIZE = "2.8rem"
export const PARAGRAPH_SIZE = "1.4rem"
